import React from "react"
import { graphql } from "gatsby"
import Navigation from "../components/navigation"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PDF from "../images/pdf.svg"
import Vision from "../images/SVG/vision.svg"
import Footer from "../components/footer"

const IndexPage = props => (
  <Layout>
    <SEO title="Descargas" />
    <Navigation />

    <div className="w-full mx-auto top-illustration">
      <Img fluid={props.data.oneImage.childImageSharp.fluid} className="h-64" />
    </div>

    <div className="w-full mx-auto text-center bg-blue-200 pt-8 pb-8">
      <h1 className="text-3xl lg:text-4xl text-blue-900">Descargas</h1>
      <div>
        <p className="w-10/12 lg:w-1/2 text-lg mt-3 mx-auto">
          Sueña en Colores cuenta con el aval de muchas instituciones
          Gubernamentales de Costa Rica. Estos documentos son muestran la
          relevancia de nuestro proyecto para los niños del país.
        </p>
      </div>
    </div>

    <div className="w-full mx-auto bg-green-100 pt-8 pb-8">
      <div className="content-item w-full lg:w-10/12 lg:flex mx-auto">
        <div className="w-full lg:w-1/4 text-center">
          <img
            src={Vision}
            alt="Mindfulness y Arteterapa"
            className="mx-auto w-1/2 lg:w-8/12"
          />
        </div>
        <div className="w-full lg:w-3/4 mt-3">
          <h2 className="text-2xl mt-10 lg:my-3 px-5 text-blue-800">
            Aval del Ministerio de Educación Pública
          </h2>
          <p className="px-5">
            Descargar (PDF){" "}
            <a
              href="/aval-mep.pdf"
              target="_blank"
              title="Aval del MEP"
              className="underline font-bold"
              style={{
                backgroundImage: `url(${PDF})`,
                backgroundRepeat: `no-repeat`,
                backgroundPositionX: `right`,
                paddingRight: `35px`,
              }}
            >
              Aval del M.E.P.
            </a>
          </p>

          <h2 className="text-2xl mt-10 lg:my-3 lg:mt-10 px-5 text-blue-800">
            Declaratoria de Utilidad Pública
          </h2>
          <p className="px-5">
            Descargar (PDF){" "}
            <a
              href="/declaratoria-utilidad-publica.pdf"
              target="_blank"
              title="Declaratoria utilidad publica"
              className="underline font-bold"
              style={{
                backgroundImage: `url(${PDF})`,
                backgroundRepeat: `no-repeat`,
                backgroundPositionX: `right`,
                paddingRight: `35px`,
              }}
            >
              Declaratoria de utilidad pública
            </a>
          </p>

          <h2 className="text-2xl mt-10 lg:my-3 lg:mt-10 px-5 text-blue-800">
            Autorización de donaciones deducibles de la renta
          </h2>
          <p className="px-5">
            Descargar (PDF){" "}
            <a
              href="/resolucion-donaciones-hacienda.pdf"
              target="_blank"
              title="Renta"
              className="underline font-bold"
              style={{
                backgroundImage: `url(${PDF})`,
                backgroundRepeat: `no-repeat`,
                backgroundPositionX: `right`,
                paddingRight: `35px`,
              }}
            >
              Autorización de deducción de donaciones de la renta
            </a>
          </p>
        </div>
      </div>
    </div>

    <div className="w-full mx-auto text-center bg-gray-100 pt-8 pb-8 border border-gray-200">
      <h1 className="text-2xl lg:text-4xl text-blue-900">Sueña en Colores</h1>
      <div className="mt-5">
        <p className="w-8/12 lg:w-1/2 text-sm lg:text-lg mt-3 mx-auto">
          Descargar (PDF){" "}
          <a
            href="/suena-colores-espanol.pdf"
            target="_blank"
            alt="Sueña en Colores"
            className="underline font-bold"
            style={{
              backgroundImage: `url(${PDF})`,
              backgroundRepeat: `no-repeat`,
              backgroundPositionX: `right`,
              paddingRight: `35px`,
            }}
          >
            Resumen de Sueña en Colores (Español)
          </a>
        </p>
        <p className="w-8/12 lg:w-1/2 text-sm lg:text-lg mt-3 mx-auto">
          Download (PDF){" "}
          <a
            href="/suena-colores-english.pdf"
            target="_blank"
            alt="Sueña en Colores"
            className="underline font-bold"
            style={{
              backgroundImage: `url(${PDF})`,
              backgroundRepeat: `no-repeat`,
              backgroundPositionX: `right`,
              paddingRight: `35px`,
            }}
          >
            About Sueña en Colores (English)
          </a>
        </p>
      </div>
    </div>

    <Footer />
  </Layout>
)

export default IndexPage

export const anaQuery = graphql`
  query {
    oneImage: file(relativePath: { eq: "servicios.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
